



































































































































































































































































import { Planning, PlanningCreateModel } from "@/api/models/planning/planning";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BAlert,
  BFormTextarea,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";

@Component({
  components: {
    BButton,
    BModal,
    BAlert,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    SearchableVueSelect,
  },
})
export default class CreatePlanning extends Vue {
  @Prop({ required: true }) open!: Boolean;
  @Prop({ required: true }) type!: string;

  selectedDay: string = "";
  displayMessage: boolean = false;

  userSelected: any = null;
  listUser: any[] = [];
  totalCountUser = 0;
  types: any = null

  planning: PlanningCreateModel = {
    lunM: false,
    lunA: false,
    marM: false,
    marA: false,
    merM: false,
    merA: false,
    jeuM: false,
    jeuA: false,
    venM: false,
    venA: false,
    message: "",
    messageLunM: "",
    messageLunA: "",
    messageMarM: "",
    messageMarA: "",
    messageMerM: "",
    messageMerA: "",
    messageJeuM: "",
    messageJeuA: "",
    messageVenM: "",
    messageVenA: "",
  };

  async created(params: any = null) {
    this.types = [this.type, this.type + ".EXT"]
    await this.loadUtilisateurs(params)
  }

  async loadUtilisateurs(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        false,
        "Nom",
        false,
        this.types
      )
      .then((res: any) => {
        this.listUser = res.items.map(
          (x: DetailsUtilisateur) =>
            <DetailsUtilisateur>{
              ...x,
              completeLabel: `${x.nom} ${x.prenom}`,
            }
        );
        this.totalCountUser = res.totalCount;
      });
  }

  closeModal() {
    this.$emit("close");
  }
  
  cellClick(selectedDay: string) {
    this.selectedDay = selectedDay;
    this.displayMessage = true;
  }

  async submit() {
    await this.$http.myressif.entreprises
      .addPlanning(
        this.$route.params.id,
        this.userSelected.id,
        this.type,
        this.planning
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Ajout d'un planning",
            text: "Ajout effectuée avec succès",
          });
          this.$emit("planning-added");
          this.displayMessage = false;
          this.resetForm();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  resetForm() {
    this.planning = {
      lunM: false,
      lunA: false,
      marM: false,
      marA: false,
      merM: false,
      merA: false,
      jeuM: false,
      jeuA: false,
      venM: false,
      venA: false,
      message: "",
      messageLunM: "",
      messageLunA: "",
      messageMarM: "",
      messageMarA: "",
      messageMerM: "",
      messageMerA: "",
      messageJeuM: "",
      messageJeuA: "",
      messageVenM: "",
      messageVenA: "",
    };
    this.userSelected = null;
  }
}
