






































































































































import {
  BSidebar,
  BSpinner,
  BForm,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormDatepicker
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PlanPrevention, UpdatePlanPreventionModel } from "@/api/models/plansPrevention/planPrevention";

@Component({
  components: {
    BSidebar,
    BCol,
    BSpinner,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    BFormFile,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker
  },
})
export default class UpdatePlanPrevention extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdatePlanPreventionSidebarActive!: boolean;
  @Prop({ required: true }) planPreventionId!: string;

  loading = false;
  required = required;
  loadingPlanPrevention = false;
  isAddFileEnabled = false;

  planPrevention: UpdatePlanPreventionModel = {};

 async created() {
    await this.$http.myressif.entreprises
      .getPlanPreventionById(
        this.$route.params.id,
        this.planPreventionId
      )
      .then((res: PlanPrevention) => {
        this.planPrevention = res;
        this.loadingPlanPrevention = true;
      });
  }

  resetForm() {
    this.planPrevention = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .updatePlanPreventionEntreprise(
        this.$route.params.id,
        this.planPrevention.id,
        this.planPrevention
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du plan de prévention",
            text: "Plan de prévention modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-plans-prevention");
        this.$emit("update:is-update-plan-prevention-sidebar-active", false);
        this.loading = false;
        this.isAddFileEnabled = false;
      });
  }
}
