







































































































































































































































































import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BAlert,
  BFormTextarea,
  BFormInput,
  BTabs,
  BTab,
  BFormGroup,
  VBTooltip,
  BTableSimple
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { PrestationSite } from "@/api/models/prestations/prestation";
import { Site } from "@/api/models/sites/site";
import { TypePreferenceVacation } from "@/api/models/enums/typePreferenceVacation";

@Component({
  components: {
    BButton,
    BModal,
    BAlert,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormTextarea,
    BFormInput,
    SearchableVueSelect,
    BTabs, 
    BTab,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTableSimple
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class AddSiteModal extends Vue {
  @Prop({ required: true }) open!: Boolean;

  typePreferenceVacation = TypePreferenceVacation

  refreshIndex = 0;

  selectedDay: string = "";
  displayMessage: boolean = false;

  newPrestationSite: PrestationSite = {}

  listSites: Site[] = []

  async created(params: any = null) {
    await this.loadListSites(params)
  }

  getPreferenceClass(typePreference: any) {
    switch(typePreference) {
      case this.typePreferenceVacation.Favori:
        return 'bg-success bg-lighten-2 text-white';
      case this.typePreferenceVacation.Possible:
        return 'bg-warning bg-lighten-2 text-white';
      case this.typePreferenceVacation.Interdit:
        return 'bg-danger bg-lighten-2 text-white';
      default:
        return 'bg-secondary bg-lighten-2'
    }
  }

  typePreferenceClicked(typePreference: TypePreferenceVacation) {
    if (typePreference >= Object.keys(this.typePreferenceVacation).length - 1) {
      return null
    } else if (typePreference == null) {
      return this.typePreferenceVacation.Favori
    } else {
      typePreference++
      return typePreference
    }
  }

  getSiteLibelle(siteId: any) {
    return this.listSites.find(s => s.id == siteId)!.libelle;
  }

  cellClick(selectedDay: string) {
    this.selectedDay = selectedDay;
    this.displayMessage = true;
  }

  async loadListSites(params: any) {
    if (!params || params?.reset) {
      this.listSites = [];
    }
    await this.$http.myressif.entreprises
      .getListSitesByEntrepriseId(
        this.$route.params.id
      )
      .then((response: Site[]) => {
        this.listSites = response;
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  closeModal() {
    this.$emit("close");
  }

  async submit() {
    this.$emit('add-site', this.newPrestationSite)
    this.resetForm()
  }

  resetForm() {
    this.newPrestationSite = {}
  }
}
