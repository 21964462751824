


























































































































































































































import { Planning, PlanningCreateModel, PlanningEditModel } from "@/api/models/planning/planning";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BAlert,
  BFormTextarea,
  BFormInput,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    BButton,
    BModal,
    BAlert,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormTextarea,
    BFormInput,
  },
})
export default class EditPLanning extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) type!: string;

  selectedDay: string = "";
  displayMessage: boolean = false;

  info: Planning["utilisateur"] = {
    nom: "",
    prenom: "",
    email: "",
    phoneNumber: "",
    externalUser: false,
    fonctions: null
  };
  planning: PlanningEditModel = {
    id: "",
    lunM: false,
    lunA: false,
    marM: false,
    marA: false,
    merM: false,
    merA: false,
    jeuM: false,
    jeuA: false,
    venM: false,
    venA: false,
    message: "",
    messageLunM: "",
    messageLunA: "",
    messageMarM: "",
    messageMarA: "",
    messageMerM: "",
    messageMerA: "",
    messageJeuM: "",
    messageJeuA: "",
    messageVenM: "",
    messageVenA: "",
  };

  async created() {
    await this.$http.myressif.entreprises
      .getPlanningById(this.$route.params.id, this.id)
      .then((res: Planning) => {
        this.planning = res;
        this.info = res.utilisateur;
      });
  }

  cellClick(selectedDay: string) {
    this.selectedDay = selectedDay;
    this.displayMessage = true;
  }

  async closeModal() {
    this.displayMessage = false;
    setTimeout(() => {
      this.$emit('update:isUpdatePlanningActive', false);
    }, 1000);
  }

  async updatePlanning() {
    await this.$http.myressif.entreprises
      .updatePlanning(
        this.$route.params.id,
        this.planning.id,
        this.type,
        this.planning
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Modification d'un planning",
            text: "Modification effectuée avec succès",
          });

          await this.$nextTick();

          this.$emit('reload');

          this.displayMessage = false;
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }
}
