




















































import { typePlanning } from "@/api/models/enums/typePlanningEnum";
import { Component, Vue, Prop } from "vue-property-decorator";
import TypePlannings from "./TypePlannings.vue";
import { BAlert, BButton, BCard, BCol, BTab, BTabs } from "bootstrap-vue";

@Component({
  components: {
    BAlert,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    TypePlannings,
  },
})
export default class ListPlannings extends Vue {
  @Prop({ required: true }) isMyEntreprise!: boolean
  typePlanning = typePlanning;
}
