







































































































import {
  BSidebar,
  BSpinner,
  BForm,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { GuideFonctionnement, UpdateGuideFonctionnementModel } from '../../../api/models/guidesFonctionnement/guideFonctionnement';
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BSidebar,
    BCol,
    BSpinner,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    BFormFile,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class UpdateGuideFonctionnement extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateGuideFonctionnementSidebarActive!: boolean;
  @Prop({ required: true }) guideFonctionnementId!: string;

  loading = false;
  required = required;
  loadingGuideFonctionnement = false;
  isAddFileEnabled = false;

  guideFonctionnement: UpdateGuideFonctionnementModel = {};

 async created() {
    await this.$http.myressif.entreprises
      .getGuideFonctionnementById(
        this.$route.params.id,
        this.guideFonctionnementId
      )
      .then((res: GuideFonctionnement) => {
        this.guideFonctionnement = res;
        this.loadingGuideFonctionnement = true;
      });
  }

  resetForm() {
    this.guideFonctionnement = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .updateGuideFonctionnementEntreprise(
        this.$route.params.id,
        this.guideFonctionnement.id,
        this.guideFonctionnement
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du guide de fonctionnement",
            text: "Guide de fonctionnement modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-guides-fonctionnement");
        this.$emit("update:is-update-guide-fonctionnement-sidebar-active", false);
        this.loading = false;
        this.isAddFileEnabled = false;
      });
  }
}
