


































































































































































































































































































































































































































































import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
} from "bootstrap-vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { InformationOperationnelle } from "@/api/models/informationsOperationnelles/informationOperationnelle";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BFormGroup,
    SearchableVueSelect,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    FeatherIcon,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class InfosOperationnelles extends Vue {
  @Prop({ required: true }) isMyEntreprise!: boolean

  infosOperationnelle: any = null;
  infosOperationnelleRES: any[] = [];
  infosOperationnelleString: any = "";
  listInfo: InformationOperationnelle[] = [];
  indexOfInfo = 0;
  refreshIndex = 0;

  async created() {
    await this.getInfosOperationnelles(
      "myressif_operationnal_metadatas_structure"
    );
    Object.keys(this.infosOperationnelle).forEach(async (x: any) => {
      this.indexOfInfo++;
      if (
        this.infosOperationnelle[x].Type == "Choice" ||
        this.infosOperationnelle[x].Type == "ChoiceMultiple"
      ) {
        await this.getInfosOperationnelles(this.infosOperationnelle[x].TypeKey);
      }
    });

    await this.getInfosOperationnellesData();
  }

  async getInfosOperationnelles(parametre: any) {
    await this.$http.myressif.parametres
      .getByNom(parametre)
      .then(async (res: InformationOperationnelle) => {
        if (parametre == "myressif_operationnal_metadatas_structure") {
          this.infosOperationnelle = res ? JSON.parse(res.valeur) : [];
        } else {
          try {
            let x = res.nom;
            console.log('begin for', res.nom)
            this.listInfo[parametre] = res ? 
              //@ts-ignore
              JSON.parse(res.valeur)?.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              }) : [];
          }
          catch(ex: any) {
            console.log('ici', ex)
          }
        }
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async getInfosOperationnellesData() {
    await this.$http.myressif.entreprises
      .getListInformationOperationnelleByEntrepriseId(this.$route.params.id)
      .then((res: any) => {
        this.infosOperationnelleRES = res
          ? JSON.parse(JSON.stringify(res))
          : [];
        setTimeout(() => {
          this.refreshIndex++
        }, 1000);
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async submit() {
    await this.$http.myressif.entreprises
      .updateInformationOperationnelle(
        this.$route.params.id,
        JSON.stringify(this.infosOperationnelleRES)
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification des informations opérationnelles",
            text: "Informations opérationnelles modifiées avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }
}
