






























































































import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BProgress,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueSlider from "vue-slider-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { AnalyseTactiqueCreateModel } from "@/api/models/analyseTactique/analyseTactique";
import { AnalyseTactiqueResultat } from "@/api/models/analyseTactique/analyseTactiqueResultat";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "vue-slider-component/theme/default.css";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BButton,
    VueSlider,
    BProgress,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // FormWizard,
    // TabContent
  },
})
export default class CreatedAnalyseTactiqueModal extends Vue {
  newAnalyseTactiqueResultat: any = {};
  listAnalyseTactiqueStructure = [];
  refreshIndex = 0;

  async created() {
    await this.getAnalyseTactique();
  }

  async getAnalyseTactique() {
    await this.$http.myressif.parametres
      .getByNom("myressif_companies_tactical_analysis_structure")
      .then((res: any) => {
        this.listAnalyseTactiqueStructure = res ? JSON.parse(res.valeur) : [];

        Object.keys(this.listAnalyseTactiqueStructure).forEach((key: any) => {
          (this.newAnalyseTactiqueResultat as any)[
            (this.listAnalyseTactiqueStructure[key] as any).Value as any
          ] = {};
        });
        Object.values(this.listAnalyseTactiqueStructure).forEach(
          (template: any) => {
            for (let i = 0; i <= template.templates.length; i++) {
              this.newAnalyseTactiqueResultat[template.Value][i] = 0;
            }
          }
        );
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  closeModal() {
    this.$emit("close");
  }

  async submit() {
    await this.$http.myressif.entreprises
      .postAnalyseTactique(this.$route.params.id, {
        valeurs: this.newAnalyseTactiqueResultat,
      })
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'une analyse tactique",
            text: "Analyse tactique créé avec succès",
          });
          this.$bvModal.hide("analyse-tactique-modal");
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-analyse-tactique");
      });
  }
}
