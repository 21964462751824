









































































































































































































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BFormSelect,
  BModal,
  BTableSimple,
  BThead,
  BBadge,
  BTr,
  BTh,
  BTbody,
  BTd,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import EditPlanning from "@/views/adherents/plannings/EditPlanning.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BBadge,
    BModal,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    EditPlanning,
  },
})
export default class DetailsPlanningAdherent extends Vue {
  @Prop({ required: true }) type!: string;
  @Prop({ required: true }) planning!: any;
  @Prop({ required: true }) info!: any;
  @Prop({ required: true }) userId!: string;
  @Prop({ required: true }) isMyEntreprise!: boolean

  selectedDay: string = "";
  messageM: string = "";
  messageA: string = "";

  displayMessage: boolean = false;
  isUpdatePlanningActive: boolean = false;

  async created() {}

  cellClick(selectedDay: string, messageM: string, messageA: string) {
    this.selectedDay = selectedDay;
    this.messageM = messageM;
    this.messageA = messageA;
    this.displayMessage = true;
  }

  async reloadPlannings() {
    this.isUpdatePlanningActive = false;
    this.$emit("reload");
  }

  async deleteItem() {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete();
        } else {
          return;
        }
      });
  }

  async confirmDelete() {
    await this.$http.myressif.entreprises
      .deletePlanning(this.$route.params.id, this.planning.id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un assistant",
            text: "Suppression effectuée avec succès",
          });
          this.$emit("reload");
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        }
      )
      .finally(() => {
        this.$emit("reload");
      });
  }

  goToUtilisateur(id: string) {
    this.$router.push({
      name: "edit-utilisateurs",
      params: { id: id },
    });
  }
}
