


































































































































































































































































































































































































































































import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
} from "bootstrap-vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { InformationOperationnelleBudget } from "@/api/models/InformationsOperationnellesBudget/informationOperationnelleBudget";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BFormGroup,
    SearchableVueSelect,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    FeatherIcon,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class InfosOperationnellesBudget extends Vue {
  @Prop({ required: true }) isMyEntreprise!: boolean

  infosOperationnelleBudget: any = null;
  infosOperationnelleBudgetRES: any[] = [];
  infosOperationnelleString: any = "";
  listInfo: InformationOperationnelleBudget[] = [];
  indexOfInfo = 0;
  refreshIndex = 0;

  async created() {
    await this.getInfosOperationnellesBudget(
      "myressif_operationnal_budget_metadatas_structure"
    );
    Object.keys(this.infosOperationnelleBudget).forEach(async (x: any) => {
      this.indexOfInfo++;
      if (
        this.infosOperationnelleBudget[x].Type == "Choice" ||
        this.infosOperationnelleBudget[x].Type == "ChoiceMultiple"
      ) {
        await this.getInfosOperationnellesBudget(this.infosOperationnelleBudget[x].TypeKey);
      }
    });

    await this.getInfosOperationnellesBudgetData();
  }

  async getInfosOperationnellesBudget(parametre: any) {
    await this.$http.myressif.parametres
      .getByNom(parametre)
      .then(async (res: InformationOperationnelleBudget) => {
        if (parametre == "myressif_operationnal_budget_metadatas_structure") {
          this.infosOperationnelleBudget = res ? JSON.parse(res.valeur) : [];
        } else {
          try {
            let x = res.nom;
            console.log('begin for', res.nom)
            this.listInfo[parametre] = res ? 
              //@ts-ignore
              JSON.parse(res.valeur)?.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              }) : [];
          }
          catch(ex: any) {
            console.log('ici', ex)
          }
        }
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async getInfosOperationnellesBudgetData() {
    await this.$http.myressif.entreprises
      .getListInformationOperationnelleBudgetByEntrepriseId(this.$route.params.id)
      .then((res: any) => {
        this.infosOperationnelleBudgetRES = res
          ? JSON.parse(JSON.stringify(res))
          : [];
        setTimeout(() => {
          this.refreshIndex++
        }, 1000);
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async submit() {
    await this.$http.myressif.entreprises
      .updateInformationOperationnelleBudget(
        this.$route.params.id,
        JSON.stringify(this.infosOperationnelleBudgetRES)
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification des informations opérationnelles budget",
            text: "Informations opérationnelles budget modifiées avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }
}
