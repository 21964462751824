




































import { Component, Vue, Prop } from "vue-property-decorator";
import DetailsPlanningAdherent from "./DetailsPlanning.vue";
import CreatePlanning from "./CreatePlanning.vue";
import { BButton, BTab } from "bootstrap-vue";
import { Planning } from "@/api/models/planning/planning";
import { typePlanning } from "@/api/models/enums/typePlanningEnum";

@Component({
  components: {
    BButton,
    BTab,
    DetailsPlanningAdherent,
    CreatePlanning,
  },
})
export default class TypePlannings extends Vue {
  @Prop({ required: true }) isMyEntreprise!: boolean
  @Prop({ required: true }) typePlanning!: any;
  typePlanningEnum = typePlanning;

  plannings: Planning[] = [];
  createPlanningActive = false;

  async mounted() {
    await this.loadPlannings();
  }

  async loadPlannings() {
    switch(this.typePlanning){
      case this.typePlanningEnum.ASST: {
        await this.loadPlanningAssistant()
        break;
      }
      case this.typePlanningEnum.IST: {
        await this.loadPlanningInfirmier()
        break;
      }
      case this.typePlanningEnum.PSY: {
        await this.loadPlanningPsychologue()
        break;
      }
      case this.typePlanningEnum.CONS: {
        await this.loadPlanningConsultant()
        break;
      }
    }
  }

  async loadPlanningAssistant() {
    await this.$http.myressif.entreprises
      .getAssistant(this.$route.params.id)
      .then((res: Planning[]) => {
        this.plannings = res;
      });
  }

  async loadPlanningConsultant() {
    await this.$http.myressif.entreprises
      .getConsultant(this.$route.params.id)
      .then((res: Planning[]) => {
        this.plannings = res;
      });
  }

  async loadPlanningPsychologue() {
    await this.$http.myressif.entreprises
      .getPsychologue(this.$route.params.id)
      .then((res: Planning[]) => {
        this.plannings = res;
      });
  }

  async loadPlanningInfirmier() {
    await this.$http.myressif.entreprises
      .getInfirmier(this.$route.params.id)
      .then((res: Planning[]) => {
        this.plannings = res;
      });
  }

  
}
