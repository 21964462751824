



























































































































import {
  BSidebar,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormFile,
  BFormDatepicker
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PlanPreventionCreateModel } from "@/api/models/plansPrevention/planPrevention";

@Component({
  components: {
    BSidebar,
    BSpinner,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormDatepicker
  },
  name: "create-plan-prevention",
})
export default class CreatePlanPrevention extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewPlanPreventionSidebarActive!: boolean;
  loading = false;

  required = required;

  newPlanPrevention: PlanPreventionCreateModel = {};

  resetForm() {
    this.newPlanPrevention = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .createPlanPreventionEntreprise(this.$route.params.id, this.newPlanPrevention)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un plan de prévention",
            text: "Plan de prévention créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-plans-prevention");
        this.$emit("update:is-add-new-plan-prevention-sidebar-active", false);
        this.loading = false;
      });
  }
}
