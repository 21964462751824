







































































































































































































































































































































































































































































































import {
  BForm,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BFormSelect,
  BModal,
  BCardText,
  BCardTitle,
  BNavItem,
  BFormSelectOption
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { DaisyEntrepriseCreateModel, Entreprise, RessifnetEntrepriseCreateModel } from "@/api/models/adherents/adherent";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { StatusEnum } from '@/api/models/enums/statusEnum';
import { CodeNaf } from '@/api/models/codesNaf/codeNaf';
import { TypeContratEnum } from '@/api/models/enums/typeContratEnum';
import ListBilansActivite from './bilansActivite/BilanActivite.vue';
import ListContacts from './contacts/Contacts.vue';
import ListContrats from "./contrats/Contrat.vue";
import ListPrestationsAdditionnelles from "./prestationsAdditionnelles/PrestationAdditionnelle.vue";
import ListPrestations from "./prestations/Prestation.vue";
import CreatePlanning from "./plannings/CreatePlanning.vue";
import ListPlannings from "./plannings/ListPlannings.vue";
import ListGuidesFonctionnement from './guidesFonctionnement/GuidesFonctionnement.vue';
import ListAnalysesTactique from './analysesTactique/AnalysesTactique.vue';
import ListPlansDeveloppement from "./plansDeveloppement/PlansDeveloppement.vue";
import ListPlansPrevention from "./plansPrevention/PlansPrevention.vue";
import ListInformationsAdditionnelles from "../informationAdditionnelles/ListInformationsAdditionnelles.vue";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";
import InfosOperationnelles from "./infosOperationnelles/InfosOperationnelles.vue";
import InfosOperationnellesBudget from "./infosOperationnellesBudget/InfosOperationnellesBudget.vue";
import RessifnetMappingsEntreprise from "./ressifnetMappings/RessifnetMapping.vue"
import DaisyMappingsEntreprise from "./daisyMappings/DaisyMapping.vue"
import ListSites from "./sites/Site.vue";
import appSettings from "@/appSettings";
import { EnumTenants } from "@/api/models/enums/enumTenants";
import { PaginatedList } from "@/api/models/common/paginatedList";

@Component({
  components: {
    BRow,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BModal,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormSelect,
    BCardText,
    BCardTitle,
    ListContacts,
    ListContrats,
    ListPrestationsAdditionnelles,
    ListPrestations,
    ListInformationsAdditionnelles,
    ListGuidesFonctionnement,
    ListAnalysesTactique,
    ListBilansActivite,
    BNavItem,
    CreatePlanning,
    ListPlannings,
    ListPlansDeveloppement,
    ListPlansPrevention,
    InfosOperationnelles,
    InfosOperationnellesBudget,
    RessifnetMappingsEntreprise,
    DaisyMappingsEntreprise,
    ListSites,
    BFormSelectOption
  },
})
export default class DetailsAdherent extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;
  adherent: Entreprise | null = null;
  mainLoading = true;

  status = StatusEnum
  newStatus = ""
  typesContrat = TypeContratEnum
  newTypeContrat = ""
  codesNaf: PaginatedList<CodeNaf> = new PaginatedList<CodeNaf>()

  required = required;

  daisySecteurActiviteId : string | null = null;
  ressifnetSecteurActiviteId : string | null = null;
  listSecteursActiviteRessifnet: PaginatedList<any> | null = null;
  listSecteursActiviteDaisy: PaginatedList<any> | null = null;

  entityType = TypeEntiteEnum.ENTREPRISE;

  async created() {
    await this.loadAdherent()
  }

  async loadAdherent() {
    await this.$http.myressif.entreprises
      .getById(this.$route.params.id)
      .then((res: Entreprise) => {
        this.adherent = res;
        this.mainLoading = false;
        this.newStatus = this.adherent.status
        this.newTypeContrat = this.adherent.typeContrat
      });
  }
  
  async changeStatus(adherent: Entreprise) {
    adherent.status = this.newStatus;
    await this.$http.myressif.entreprises
      .update(this.$route.params.id, adherent)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Modification du statut",
            text: "Modification effectuée avec succès",
          });
        },
        (error: any) => {
          errorAlert.fire({
            title: "Oops",
            text: error.message,
          });
        }
      );
      await this.loadAdherent()
  }

  async changeCodeNaf(adherent: Entreprise) {
    await this.$http.myressif.entreprises
      .update(this.$route.params.id, adherent)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Modification du code NAF",
            text: "Modification effectuée avec succès",
          });
        },
        (error: any) => {
          errorAlert.fire({
            title: "Oops",
            text: error.message,
          });
        }
      );
      await this.loadAdherent()
  }

  async changeTypeContrat(adherent: Entreprise) {
    adherent.typeContrat = this.newTypeContrat;
    await this.$http.myressif.entreprises
      .update(this.$route.params.id, adherent)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Modification du type de contrat",
            text: "Modification effectuée avec succès",
          });
        },
        (error: any) => {
          errorAlert.fire({
            title: "Oops",
            text: error.message,
          });
        }
      );
      await this.loadAdherent()
  }

  async searchCodesNaf(params: any) {
    await this.$http.myressif.codesNaf
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<CodeNaf>) => {
        this.codesNaf.items = this.codesNaf!.items.concat(
            response.items.filter(
              (ri) => !this.codesNaf!.items.some((cn) => cn.code == ri.code)
            ).map((x: CodeNaf) => <CodeNaf>{
          ...x,
          completeLabel: `${x.code} - ${x.libelle}`
        })
      )});
  }

  async searchSecteursActiviteRessifnet(params: any) {
    if (!params || params?.reset) {
      this.listSecteursActiviteRessifnet = new PaginatedList<any>();
    }

    await this.$http.ressifnet.secteursActivite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 50,
        params?.search ?? ""
      )
      .then((response: PaginatedList<any>) => {
        this.listSecteursActiviteRessifnet = response;
      });
  }
  async searchSecteursActiviteDaisy(params: any) {
    if (!params || params?.reset) {
      this.listSecteursActiviteDaisy = new PaginatedList<any>();
    }

    await this.$http.daisy.secteursActivite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 50,
        params?.search ?? ""
      )
      .then((response: PaginatedList<any>) => {
        this.listSecteursActiviteDaisy = response;
      });
  }

  async askPropagateRessifnet() {
    await this.searchSecteursActiviteRessifnet({})
      .then(() => {
        this.$bvModal.show('modal-propagate-ressifnet');
      })
  }
  async askPropagateDaisy() {
    await this.searchSecteursActiviteDaisy({})
      .then(() => {
        this.$bvModal.show('modal-propagate-daisy');
      })
  }

  
  async confirmPropagateRessifnet() 
  {
    let newMapping: RessifnetEntrepriseCreateModel = {
      entrepriseId: this.$route.params.id,
      secteurActiviteRessifnetId: this.ressifnetSecteurActiviteId!,
    };
    await this.$http.ressifnet.entreprises.post(newMapping).then(
      (response: any) => {
        successAlert.fire({
          title: "Propagation d'un adhérent",
          text: "Adhérent propagé dans Ressifnet avec succès",
          customClass: {
            confirmButton: "btn btn-success",
          },
          confirmButtonText: "D'accord, merci !",
        });
      },
      (error: any) => {
        this.$swal({
          title: "Attention !",
          text: "L'adhérent n'a pas pu être ajouté à Ressifnet",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
          confirmButtonText: "D'accord, merci !",
        });
      }
    );
  }

  async confirmPropagateDaisy() {
    let newMapping: DaisyEntrepriseCreateModel = {
      entrepriseId: this.$route.params.id,
      secteurActiviteDaisyId: this.daisySecteurActiviteId!,
    };
    await this.$http.daisy.entreprises.post(newMapping).then(
      (response: any) => {
        successAlert.fire({
          title: "Propagation d'un adhérent",
          text: "Adhérent propagé dans Daisy avec succès",
          customClass: {
            confirmButton: "btn btn-success",
          },
          confirmButtonText: "D'accord, merci !",
        });
      },
      (error: any) => {
        this.$swal({
          title: "Attention !",
          text: "L'adhérent n'a pas pu être ajouté à Daisy",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
          confirmButtonText: "D'accord, merci !",
        });
      }
    );
  }

  askDeleteAdherent(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer l'adhérent ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id : string) {  
    await this.$http.myressif.entreprises.delete(id)
      .then(async(response: string) => {
        successAlert.fire({
          title: "Suppression d\'un adhérent",
          text: "Suppression effectuée avec succès"
        })
        this.$router.push({ name: "adherents" });
      }, 
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      })
  }

  goToList() {
    this.$router.push({ name: "adherents" });
  }
  update(id: string) {
    this.$router.push({ name: "modification-adherent" });
  }
}
