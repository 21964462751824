

























































import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BModal,
  BFormTextarea,
  BTable,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { AnalyseTactique } from "@/api/models/analyseTactique/analyseTactique";
import { Entreprise } from "@/api/models/adherents/adherent";
import { PaginatedList } from "@/api/models/common/paginatedList";
import CreatedAnalyseTactiqueModal from "@/views/adherents/analysesTactique/CreateAnalyseTactique.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BTable,
    CreatedAnalyseTactiqueModal,
  },
})
export default class ListAnalysesTactique extends Vue {
  @Prop({ required: true }) adherent!: Entreprise;
  perPage = 10;
  currentPage = 1;

  // Servira pour afficher les dernière information de l'analyse
  analyseTactique: AnalyseTactique = {};
  analyseTactiqueMapping: any = [];
  finalAnalyseTactiqueMapping: any = [];
  tableOfResult: any = [];
  axes: any = [];
  paginatedListAnalysesTactique = new PaginatedList<AnalyseTactique>();

  mainLoading = false;
  tableColumns: any = [];
  additionnalHeader = [];

  async created() {
    await this.getAnalyseTactiqueMapping();
    await this.getFinalAnalyseTactiqueMapping();
    await this.loadListAnalysesTactique({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
    await this.loadHeader(this.paginatedListAnalysesTactique);
  }

  async getAnalyseTactiqueMapping() {
    await this.$http.myressif.parametres
      .getByNom("myressif_companies_tactical_analysis_axis_value_mapping")
      .then((res: any) => {
        this.analyseTactiqueMapping = res ? JSON.parse(res.valeur) : [];
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async getFinalAnalyseTactiqueMapping() {
    await this.$http.myressif.parametres
      .getByNom("myressif_companies_tactical_analysis_finality_value_mapping")
      .then((res: any) => {
        this.finalAnalyseTactiqueMapping = res ? JSON.parse(res.valeur) : [];
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async loadHeader(
    paginatedListAnalysesTactique: PaginatedList<AnalyseTactique>
  ) {
    var result: AnalyseTactique;
    // for(result in paginatedListAnalysesTactique.items)
    // {
    //   console.log("ANALYSE TACTIQUE : ", result)
    // }
  }

  async loadListAnalysesTactique(params: any) {
    if (!params || params?.reset) {
      this.paginatedListAnalysesTactique = new PaginatedList<AnalyseTactique>();
    }
    await this.$http.myressif.entreprises
      .getListAnalysesTactiqueByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<AnalyseTactique>) => {
        this.axes = []
        this.paginatedListAnalysesTactique = response;
        Object.entries(this.analyseTactiqueMapping).forEach((e: any) => {
          this.axes.push({
            key: e[0],
            label: e[1][0].name,
          });
        });

        Object.values(this.paginatedListAnalysesTactique.items).forEach(
          (analyse: any, index: any) => {
            analyse.resultats.forEach((res: any) => {
              analyse[res.axe] = this.analyseTactiqueMapping[res.axe].find(
                (x: any) => res.valeur > x.from && res.valeur < x.to
              ).name;
            });

            analyse.note = this.finalAnalyseTactiqueMapping.find(
              (parametre: any) =>
                Object.keys(parametre.Values).every(
                  (valParam: any) =>
                    analyse[valParam] == parametre.Values[valParam]
                )
            ).Name;
          }
        );
      })
      .finally(() => {
        let excludeProps = ["date", "id", "note", "resultats"];
        let lastResult: any = this.paginatedListAnalysesTactique.items[0];
        if(lastResult) {
          this.analyseTactique.note = lastResult.note;
          Object.keys(lastResult)
          .filter((x) => !excludeProps.includes(x))
          .forEach((a) => {
            this.axes.find((x: any) => x.key == a).resultat = lastResult[a];
          });
          
          this.tableColumns.push({
            key: "date",
          });
          Object.entries(this.analyseTactiqueMapping).forEach((e: any) => {
            this.tableColumns.push({
              key: e[0],
              label: e[1][0].name,
            });
          });
          this.tableColumns.push({
            key: "note",
          });
        }
        
      });
  }

  // myRowClickHandler(record: any, index: any) {
  //   let recordGood = this.paginatedListAnalysesTactique.items.find(
  //     (listItem: any) => record.id == listItem.id
  //   );

  //   let test = this.axes.find((axe: any) => {
  //     return axe.key == recordGood[axe.key];
  //   });
  //   console.log(test);
  // }

  async createEnquete() {
    this.$bvModal.show("analyse-tactique-modal");
  }
}
