








































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BSpinner, 
  BTable,
  BBadge
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/adherents/adherent";
import UpdatePlanPrevention from "./UpdatePlanPrevention.vue";
import CreatePlanPrevention from "./CreatePlanPrevention.vue";
import { PlanPrevention } from "@/api/models/plansPrevention/planPrevention";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    CreatePlanPrevention,
    UpdatePlanPrevention, 
  },
})
export default class ListPlansPrevention extends Vue {
  @Prop({ required: true }) adherent!: Entreprise;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewPlanPreventionSidebarActive = false;
  isUpdatePlanPreventionSidebarActive = false;
  selectedPlanPreventionId = "";
  loading = true;

  tableColumns = ['nomFichier', 'description', 'dateSignature', 'dateValidite', 'actions'];
  paginatedListPlansPrevention = new PaginatedList<PlanPrevention>();

  async created() {
    await this.loadListPlansPrevention({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListPlansPrevention.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListPlansPrevention.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListPlansPrevention({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectPlanPrevention(planPreventionId: string) {
    this.selectedPlanPreventionId = planPreventionId;
    this.isUpdatePlanPreventionSidebarActive = true;
  }

  async downloadFile(adherentId: string, planPreventionId: string) {
    await this.$http.myressif.entreprises
      .downloadPlanPrevention(adherentId, planPreventionId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadListPlansPrevention(params: any) {
    if (!params || params?.reset) {
      this.paginatedListPlansPrevention = new PaginatedList<PlanPrevention>();
    }
    await this.$http.myressif.entreprises
      .getListPlansPreventionByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<PlanPrevention>) => {
        this.paginatedListPlansPrevention = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce plan de prévention ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.entreprises
      .deletePlanPrevention(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un plan de prévention",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.paginationChanged();
      });
  }
}
