


























































































































































































































































import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BModal,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BAlert,
  BFormTextarea,
  BFormInput,
  BTab,
  BTabs,
  BFormCheckbox,
  VBTooltip,
  BFormGroup,
  BFormDatepicker,
  BFormSelect
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PrestationSite } from "@/api/models/prestations/prestation";
import { Site } from "@/api/models/sites/site";
import { TypePreferenceVacation } from "@/api/models/enums/typePreferenceVacation";

@Component({
  components: {
    BButton,
    BModal,
    BAlert,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormTextarea,
    BFormInput,
    BTabs,
    BTab,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    BFormGroup,
    BFormDatepicker,
    BFormSelect
  },directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class UpdateSiteModal extends Vue {
  @Prop({ required: true }) prestationSite!: PrestationSite;
  @Prop({ required: true }) open!: boolean;

  typePreferenceVacation = TypePreferenceVacation

  refreshIndex = 0;

  listSites: Site[] = []

  async created(params: any = null) {
    await this.loadListSites(params)
  }

  getSiteLibelle(siteId: any) {
    return this.listSites.find(s => s.id == siteId)!.libelle;
  }

  getPreferenceClass(typePreference: any) {
    switch(typePreference) {
      case this.typePreferenceVacation.Favori:
        return 'bg-success bg-lighten-2 text-white';
      case this.typePreferenceVacation.Possible:
        return 'bg-warning bg-lighten-2 text-white';
      case this.typePreferenceVacation.Interdit:
        return 'bg-danger bg-lighten-2 text-white';
      default:
        return 'bg-secondary bg-lighten-2'
    }
  }

  typePreferenceClicked(typePreference: TypePreferenceVacation) {
    if (typePreference >= Object.keys(this.typePreferenceVacation).length - 1) {
      return null
    } else if (typePreference == null) {
      return this.typePreferenceVacation.Favori
    } else {
      typePreference++
      return typePreference
    }
  }

   async loadListSites(params: any) {
    if (!params || params?.reset) {
      this.listSites = [];
    }
    await this.$http.myressif.entreprises
      .getListSitesByEntrepriseId(
        this.$route.params.id
      )
      .then((response: Site[]) => {
        this.listSites = response;
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  closeModal() {
    this.$emit("close");
  }

  async submit() {
    this.$emit('update-site', this.prestationSite)
    this.resetForm()
  }

  resetForm() {
    this.prestationSite = {}
  }
}
