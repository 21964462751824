



























































































































































































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BFormTextarea,
  BRow,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormFile,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { PrestationCreateModel, PrestationIntervenantCreateModel } from "@/api/models/prestations/prestation";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Structure } from "@/api/models/structures/structure";
import { Agence } from "@/api/models/structures/agence";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Contrat } from "@/api/models/contrats/contrat";
import { Site } from "@/api/models/sites/site";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormDatepicker,
    BFormFile,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "create-prestation",
})
export default class CreatePrestation extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewPrestationSidebarActive!: boolean;
  loading = false;

  required = required;

  listTypesContrat = [];

  totalCountTypePrestation = 0;
  totalCountIntervenants = 0;

  newPrestation: PrestationCreateModel = {
    prestationsIntervenants: []
  };
  listStructures = new PaginatedList<Structure>();
  listContrats = new PaginatedList<Contrat>();
  listAgences = new PaginatedList<Agence>();
  listIntervenants = new PaginatedList<any>();
  listSites: Site[] = []
  intervenantsSelected: DetailsUtilisateur[] = []

  async created() {
    await this.getTypeContrat();
    await this.searchStructures(null);
    await this.searchContrats(null);
    await this.searchIntervenants(null);
    await this.loadListSites(null);
  }

  resetForm() {
    this.newPrestation = {
      prestationsIntervenants: []
    };
    this.intervenantsSelected = []
  }

  completeLabelFromUserId(intervenantId: string) {
    return this.listIntervenants!.items.find(i => i.id == intervenantId)?.completeLabel
  }

  async getTypeContrat() {
    await this.$http.myressif.parametres
      .getByNom("plannings_prestations_typeContrat")
      .then((res: any) => {
        if (res.valeur) {
          this.listTypesContrat = JSON.parse(res.valeur);
        }
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async searchStructures(params: any) {
    if (!params || params?.reset) {
      this.listStructures = new PaginatedList<any>();
    }

    await this.$http.myressif.structures
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<any>) => {
        this.listStructures = response;
      });
  }

  async searchContrats(params: any) {
    if (!params || params?.reset) {
      this.listContrats = new PaginatedList<any>();
    }

    await this.$http.myressif.entreprises
      .getListContratsByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        params?.orderBy ?? 'nom',
        params?.orderBy ?? false
      )
      .then((response: PaginatedList<any>) => {
        this.listContrats = response;
      });
  }

  async searchAgences(params: any) {
    this.listAgences = new PaginatedList<any>();

    await this.$http.myressif.structures
      .getListAgencesByStructureId(
        this.newPrestation.structureId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then((response: PaginatedList<any>) => {
        this.listAgences = response;
      });
  }

  async searchIntervenants(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listIntervenants.items = this.listIntervenants!.items.concat(
            response.items.filter(
              (ri) => !this.listIntervenants!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.totalCountIntervenants = response.totalCount;
      });
  }

  async loadListSites(params: any) {
    if (!params || params?.reset) {
      this.listSites = [];
    }
    await this.$http.myressif.entreprises
      .getListSitesByEntrepriseId(
        this.$route.params.id
      )
      .then((response: Site[]) => {
        this.listSites = response;
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };;
  }

  @Watch('intervenantsSelected')
  selectIntervenant()
  {
    let itemToAdd = this.intervenantsSelected.filter(si => !this.newPrestation.prestationsIntervenants?.some(pi => si.id === pi.intervenantId))[0];
    if (itemToAdd) {
      var newPI: PrestationIntervenantCreateModel = {
        intervenantId: itemToAdd?.id,
      } 
      this.newPrestation.prestationsIntervenants?.push(newPI);

      return;
    }

    let itemToDelete = this.newPrestation.prestationsIntervenants?.filter(pi => this.intervenantsSelected?.some(i => pi.intervenantId !== i.id))[0];
    if (itemToDelete) {
      this.newPrestation.prestationsIntervenants = this.newPrestation.prestationsIntervenants?.filter(pi => pi.intervenantId != itemToDelete?.intervenantId)
    }
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .createPrestationOnEntreprise(this.$route.params.id, this.newPrestation)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'une prestation",
            text: "Prestation créée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-prestation");
        this.$emit("update:is-add-new-prestation-sidebar-active", false);
        this.loading = false;
      });
  }
}
