













































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { PrestationAdditionnelle } from "@/api/models/prestationsAdditionnelles/prestationAdditionnelle";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/adherents/adherent";
import UpdatePrestationAdditionnelle from "./EditPrestationAdditionnelle.vue";
import CreatePrestationAdditionnelle from "./CreatePrestationAdditionnelle.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BSpinner,
    UpdatePrestationAdditionnelle,
    CreatePrestationAdditionnelle,
  },
})
export default class ListPrestationsAdditionnelles extends Vue {
  @Prop({ required: true }) adherent!: Entreprise;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewPrestationAdditionnelleSidebarActive = false;
  isUpdatePrestationAdditionnelleSidebarActive = false;
  selectedPrestationAdditionnelleId = "";

  tableColumns = [
    "dateVente",
    "fichier",
    "type",
    "prix",
    "actions",
  ];
  paginatedListPrestationsAdditionnelles = new PaginatedList<PrestationAdditionnelle>();

  listTotalPrestationsAdditionnelles: {annee: number, total: number}[] = []

  async created() {
    await this.loadListPrestationAdditionnelle({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
    // await this.countTotauxPrestationsAdditionnelles();
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListPrestationsAdditionnelles.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListPrestationsAdditionnelles.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListPrestationAdditionnelle({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectPrestationAdditionnelle(prestationAdditionnelleId: string) {
    this.selectedPrestationAdditionnelleId = prestationAdditionnelleId;
    this.isUpdatePrestationAdditionnelleSidebarActive = true;
  }

  async downloadFile(adherentId: string, prestationAdditionnelleId: string) {
    await this.$http.myressif.entreprises
      .downloadPrestationAdditionnelle(adherentId, prestationAdditionnelleId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadListPrestationAdditionnelle(params: any) {
    if (!params || params?.reset) {
      this.paginatedListPrestationsAdditionnelles = new PaginatedList<PrestationAdditionnelle>();
    }
    await this.$http.myressif.entreprises
      .getListPrestationsAdditionnellesByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(async (response: PaginatedList<PrestationAdditionnelle>) =>  {
        this.paginatedListPrestationsAdditionnelles = response;
        await this.countTotauxPrestationsAdditionnelles();
      });
  }

  async countTotauxPrestationsAdditionnelles() {
    console.log("couuuunt")
    await this.$http.myressif.entreprises
      .countTotauxPrestationsAdditionnellesByEntrepriseId(
        this.$route.params.id
      )
      .then((response: any) => {
        this.listTotalPrestationsAdditionnelles = response
      })
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cette prestation additionnelle ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.entreprises
      .deletePrestationAdditionnelleEntreprise(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une prestation additionnelle",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListPrestationAdditionnelle(null);
      });
  }
}
