






























































































































































































































































































































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BFormTextarea,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Prestation, PrestationUpdateModel, PrestationIntervenantCreateModel, PrestationSite, PrestationIntervenant } from "@/api/models/prestations/prestation";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { integer } from "vee-validate/dist/rules";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Structure } from "@/api/models/structures/structure";
import { Agence } from "@/api/models/structures/agence";
import { ShortUtilisateur, DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Contrat } from "@/api/models/contrats/contrat";
import { Site } from "@/api/models/sites/site";
import AddSiteModal from "./AddSiteModal.vue";
import UpdateSiteModal from "./UpdateSiteModal.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormDatepicker,
    AddSiteModal,
    UpdateSiteModal
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "update-prestation",
})
export default class EditPrestation extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdatePrestationSidebarActive!: boolean;
  @Prop({ required: true }) prestationId!: string;

  refreshIndex = 0

  loading = false;
  required = required;
  loadingContact = false;

  isAddNewSiteModalActive = false
  isUpdateNewSiteModalActive = false

  listTypesContrat = [];

  prestation: PrestationUpdateModel = {};
  listAgences = new PaginatedList<Agence>();
  listStructures = new PaginatedList<Structure>();
  listIntervenants = new PaginatedList<ShortUtilisateur>();
  listContrats = new PaginatedList<Contrat>();
  listSites: Site[] = []

  prestationSiteSelected: PrestationSite | null = null

  totalCountTypePrestation = 0
  totalCountIntervenants = 0;

  intervenantsSelectedIds: string[] = []


  async created() {
    await this.$http.myressif.entreprises
      .getPrestationById(this.$route.params.id, this.prestationId)
      .then(async (res: Prestation) => {
        this.prestation = res;
        await this.searchAgences(null);
        this.prestation.agencesIds = res.agences?.map((a) => { return a.id })
        this.prestation.sitesIds = res.sites?.map((s) => { return s.id })
        this.loadingContact = true;

        if (this.prestation.prestationsIntervenants) {
          this.prestation.prestationsIntervenants.forEach((pi) => {
            this.intervenantsSelectedIds.push(pi.intervenantId!)
            this.listIntervenants.items.push({
              id: pi.intervenantId!,
              completeLabel: pi.intervenantCompleteLabel,
              nom: pi.intervenantNom,
              prenom: pi.intervenantPrenom
            })
          });
        }
    });
    await this.searchIntervenants(null)
    await this.getTypeContrat();
  }

  resetForm() {
    this.prestation = {};
  }

  selectPrestationSite(prestationSite: PrestationSite) {
    this.prestationSiteSelected = prestationSite
    this.isUpdateNewSiteModalActive = true
  }

  addSite(newPrestationSite: PrestationSite) {
    this.prestation.prestationsSites?.push(newPrestationSite)
    this.refreshIndex++
  }

  updateSite(prestationSite: PrestationSite) {
    var index = this.prestation.prestationsSites?.findIndex(ps => ps.siteId == prestationSite.siteId);
    this.prestation.prestationsSites![index!] = prestationSite
  }

  completeLabelFromUserId(intervenantId: string) {
    return this.listIntervenants!.items.find(i => i.id == intervenantId)?.completeLabel
  }

  async loadPrestationsIntervenants() {
    this.prestation.prestationsIntervenants?.forEach((pi) => {
      this.intervenantsSelectedIds.push(pi.intervenantId!)
    })
    await this.searchIntervenants(null);
  }

  async getTypeContrat() {
    await this.$http.myressif.parametres
      .getByNom("plannings_prestations_typeContrat")
      .then((res: any) => {
        if (res.valeur) {
          this.listTypesContrat = JSON.parse(res.valeur);
        }
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async searchStructures(params: any) {
    if (!params || params?.reset) {
      this.listStructures = new PaginatedList<any>();
    }

    await this.$http.myressif.structures
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<any>) => {
        this.listStructures = response;
      });
  }

  async searchContrats(params: any) {
    if (!params || params?.reset) {
      this.listContrats = new PaginatedList<any>();
    }

    await this.$http.myressif.entreprises
      .getListContratsByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        params?.orderBy ?? 'nom',
        params?.orderBy ?? false,
      )
      .then((response: PaginatedList<any>) => {
        this.listContrats = response;
      });
  }

  async searchAgences(params: any) {
    this.listAgences = new PaginatedList<any>();

    await this.$http.myressif.structures
      .getListAgencesByStructureId(
        this.prestation.structureId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
      )
      .then((response: PaginatedList<any>) => {
        this.listAgences = response;
      });
  }

  async searchIntervenants(params: any) {
    if (!params || params?.reset) {
      this.listIntervenants.items = this.listIntervenants.items?.filter(e => this.intervenantsSelectedIds.includes(e.id));
    }

    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listIntervenants.items = this.listIntervenants!.items.concat(
            response.items.filter(
              (ri) => !this.listIntervenants!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: ShortUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.totalCountIntervenants = response.totalCount;
      });
  }

  // @Watch('intervenantsSelectedIds')
  selectIntervenant()
  {
    let idToAdd = this.intervenantsSelectedIds.filter(si => !this.prestation.prestationsIntervenants?.some(pi => si === pi.intervenantId))[0];
    if (idToAdd) {
      var newPI: PrestationIntervenantCreateModel = {
        intervenantId: idToAdd,
      } 
      this.prestation.prestationsIntervenants?.push(newPI);

      return;
    }

    let itemToDelete = this.prestation.prestationsIntervenants?.filter(pi => this.intervenantsSelectedIds?.some(i => pi.intervenantId !== i))[0];
    if (itemToDelete) {
      this.prestation.prestationsIntervenants = this.prestation.prestationsIntervenants?.filter(pi => pi.intervenantId != itemToDelete?.intervenantId)
    }
  }

  askRemoveSite(site: PrestationSite) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer le site "+ site.siteLibelle +" ?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.removeSite(site.siteId!);
        } else {
          return;
        }
      });
  }

	async removeSite(siteId: string) {
    const index = this.prestation.prestationsSites?.findIndex(ps => ps.siteId == siteId);

    if (!index) return

    if (index > -1) {
      this.prestation.prestationsSites!.splice(index, 1);
    }

    this.refreshIndex++
	}

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .updatePrestationEntreprise(
        this.$route.params.id,
        this.prestation.id,
        this.prestation
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification d'une prestation",
            text: "Prestation modifiée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-prestation");
        this.$emit("update:is-update-prestation-sidebar-active", false);
        this.loading = false;
      });
  }
}
