



















































































































import {
  BSidebar,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { BilanActiviteCreateModel } from "@/api/models/bilansActivite/bilanActivite";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";

@Component({
  components: {
    BSidebar,
    BSpinner,
    BButton,
    BFormInput,
    BFormDatepicker,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    SearchableVueSelect,
  },
  name: "create-bilan-activite",
})
export default class CreateBilanActivite extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewBilanActiviteSidebarActive!: boolean;
  loading = false;

  listTypesBilans: any[] = [];
  totalCountTypesBilans = 0;
  required = required;

  newBilanActivite: BilanActiviteCreateModel = {};

  async created() {
    await this.loadTypesBilans();
  }

  resetForm() {
    this.newBilanActivite = {};
  }

  async loadTypesBilans() {
    await this.$http.myressif.parametres
      .getByNom("entreprise_type_bilan_activite")
      .then((res: any) => {
        if (res.valeur) {
          let typesBilans = JSON.parse(res.valeur);
          typesBilans.forEach((itm: any) => {
            let bilan = [...Array(3)].map(
              (a, b) => itm + " " + (new Date().getFullYear() - 1 + b)
            );
            this.listTypesBilans.push(...bilan);
          });
        }
      });
  }

  async submit() {
   
    this.loading = true;
    await this.$http.myressif.entreprises
      .createBilanActiviteEntreprise(
        this.$route.params.id,
        this.newBilanActivite
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un bilan d'activité",
            text: "Bilan d'activité créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-bilans-activite");
        this.$emit("update:is-add-new-bilan-activite-sidebar-active", false);
        this.loading = false;
      });
  }
}
