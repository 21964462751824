



































































































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Contact } from "@/api/models/contacts/contact";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAvatar,
  BSpinner,
  BAlert,
  VBPopover,
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/adherents/adherent";
import AppCollapse from "@/@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@/@core/components/app-collapse/AppCollapseItem.vue"
import { Site } from "@/api/models/sites/site";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import CreateSite from "./CreateSite.vue";
import EditSite from "./EditSite.vue";

@Component({
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BAvatar,
    BSpinner,
    SearchableVueSelect,
    CreateSite,
    EditSite,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
  'b-popover': VBPopover
},
})
export default class ListSites extends Vue {
  @Prop({ required: true }) adherents!: Entreprise;

  perPage = 5;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];

  listSites: Site[] = []

  paginatedListContactsBySite = new PaginatedList<Contact>();
  paginatedListContacts = new PaginatedList<Contact>();

  contactSelected: Contact | null = null
  siteSelected: Site | null = null

  showContact = false

  tableColumns = [
  { key: "contact", sortable: false },
  { key: "telephone", sortable: false },
  { key: "fonction", sortable: true },
  { key: "droitsDiffusion", sortable: false },
  { key: "actions" },
  ];

  isAddNewSiteSidebarActive = false
  isUpdateSiteSidebarActive = false

  selectedSiteId = ""

  get paginationFrom() {
  return (
    this.perPage * (this.currentPage - 1) +
    (this.paginatedListContactsBySite.items.length ? 1 : 0)
  );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListContactsBySite.items.length
    );
  }

  async created() {
    await this.loadListSite()
    await this.loadListContact(null)
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    if (this.siteSelected)
    await this.loadListContactBySite(this.siteSelected?.id, {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectSite(siteId: string) {
    this.selectedSiteId = siteId;
    this.isUpdateSiteSidebarActive = true;
  }

  async loadListSite() {
    await this.$http.myressif.entreprises
      .getListSitesByEntrepriseId(
        this.$route.params.id
      )
      .then((response: Site[]) => {
        this.listSites = response;
      });
  }

  async loadListContactBySite(siteId: string, params: any){
    this.showContact = true
    await this.$http.myressif.entreprises
    .getListContactBySite(
      this.$route.params.id,
      siteId,
      params?.pageNumber ?? 1,
      params?.pageSize ?? 5,
      params?.search ?? "",
      "Nom",
      false
    )
    .then((response: PaginatedList<Contact>) => {
      this.paginatedListContactsBySite = response;
    });
  }

  async loadListContact(params: any) {
  if (!params || params?.reset) {
    this.paginatedListContacts = new PaginatedList<Contact>();
  }
  await this.$http.myressif.entreprises
    .getListContactsByEntrepriseId(
      this.$route.params.id,
      params?.pageNumber ?? 1,
      params?.pageSize ?? 10,
      null,
      false,
      params?.search ?? ""
    )
    .then((response: PaginatedList<Contact>) => {
      this.paginatedListContacts.items = this.paginatedListContacts.items!.concat(
            response.items.filter(
              (ri) => !this.paginatedListContacts.items!.some((lsi) => lsi.id == ri.id)
            ).map((x: Contact) => <Contact>{
              ...x,
              completeLabel: `${x.nom} ${x.prenom}`,
            })
          );
      this.paginatedListContacts.totalCount = response.totalCount
    });
  }

  async addContactSite() {
    var data = {
      entrepriseId : this.$route.params.id,
      siteId: this.siteSelected?.id,
      contactId: this.contactSelected?.id
    }
    await this.$http.myressif.entreprises
      .addContactOnSiteEntreprise(
        this.$route.params.id,
        this.siteSelected?.id,
        this.contactSelected?.id,
        data
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Ajout d'un contact",
            text: "Contact ajouté avec succès",
          });
          data.siteId ? await this.loadListContactBySite(data.siteId, null) : false
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      ).finally(async () => {
        this.resetForm()
      })
  }

  async askDeleteContactOnSite(siteId: string, contactId: string) {
  this.$bvModal
    .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce contact ?", {
      title: "Confirmation de suppression",
      size: "sm",
      okVariant: "primary",
      okTitle: "Oui",
      cancelTitle: "Non",
      cancelVariant: "outline-secondary",
      hideHeaderClose: false,
      centered: true,
    })
    .then(async (value) => {
      if (value === true) {
        await this.confirmDeleteContactOnSite(siteId, contactId);
      } else {
        return;
      }
    });
  }

  async confirmDeleteContactOnSite(siteId: string, contactId: string) {
    await this.$http.myressif.entreprises.deleteContactOnSiteEntreprise(this.$route.params.id, siteId, contactId).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un contact",
          text: "Suppression effectuée avec succès",
        });
        await this.loadListContactBySite(siteId, null)

      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  async askDeleteSite(siteId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce site ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDeleteSite(siteId);
        } else {
          return;
        }
      });
  }

  async confirmDeleteSite(siteId: string) {
    await this.$http.myressif.entreprises.deleteSiteEntreprise(this.$route.params.id, siteId).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un site",
          text: "Suppression effectuée avec succès",
        });
        await this.loadListSite()

      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  resetForm(){
    this.contactSelected = null
  }
  
  async clearListContact(){
    this.siteSelected = null
    this.showContact = false
    this.paginatedListContactsBySite = new PaginatedList<Contact>();
  }
}
