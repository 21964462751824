












































































































import {
  BSidebar,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormFile,
  BFormTextarea
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PlanDeveloppementCreateModel } from "@/api/models/plansDeveloppement/planDeveloppement";
@Component({
  components: {
    BSidebar,
    BSpinner,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormTextarea
  },
  name: "create-plan-developpement",
})
export default class CreatePlanDeveloppement extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewPlanDeveloppementSidebarActive!: boolean;
  loading = false;

  required = required;

  newPlanDeveloppement: PlanDeveloppementCreateModel = {};

  resetForm() {
    this.newPlanDeveloppement = {};
  }

  async submit() {
    console.log('new', this.newPlanDeveloppement)
    this.loading = true;
    await this.$http.myressif.entreprises
      .createPlanDeveloppementEntreprise(this.$route.params.id, this.newPlanDeveloppement)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un plan de développement",
            text: "Plan de développement créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-plans-developpement");
        this.$emit("update:is-add-new-plan-developpement-sidebar-active", false);
        this.loading = false;
      });
  }
}
