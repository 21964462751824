






























































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Prestation } from "@/api/models/prestations/prestation";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import UpdatePrestation from "./EditPrestation.vue";
import CreatePrestation from "./CreatePrestation.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BSpinner,
    UpdatePrestation,
    CreatePrestation,
  },
})
export default class AdherentListPrestations extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewPrestationSidebarActive = false;
  isUpdatePrestationSidebarActive = false;
  selectedPrestationId = "";

  tableColumns = [
    { key: "nom", sortable: true },
    { key: "typeContrat", label: "Type de contrat", sortable: true },
    { key: "structureNom", label: "Structure", sortable: true },
    { key: "dateDebut", label: "Date de début", sortable: true },
    { key: "actions" },
  ];
  paginatedListPrestations = new PaginatedList<Prestation>();

  async created() {
    await this.loadListPrestation({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListPrestations.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListPrestations.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListPrestation({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectPrestation(prestationId: string) {
    this.selectedPrestationId = prestationId;
    this.isUpdatePrestationSidebarActive = true;
  }

  async loadListPrestation(params: any) {
    if (!params || params?.reset) {
      this.paginatedListPrestations = new PaginatedList<Prestation>();
    }
    await this.$http.myressif.entreprises
      .getListPrestationsByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Prestation>) => {
        this.paginatedListPrestations = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cette prestation ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.entreprises
      .deletePrestationEntreprise(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une prestation",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListPrestation(null);
      });
  }
}
