




































































































import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BTab,
  BTabs,
  BCardText,
  BFormTextarea
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PlanDeveloppement } from "@/api/models/plansDeveloppement/planDeveloppement";
import CreatePlanDeveloppement from "./CreatePlanDeveloppement.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BTab,
    BTabs,
    BCardText,
    BFormTextarea,
    CreatePlanDeveloppement,
  },
})
export default class ListPlansDeveloppement extends Vue {
  @Prop({ required: true }) isMyEntreprise!: boolean
  isAddNewPlanDeveloppementSidebarActive = false;

  listPlansDeveloppement: PlanDeveloppement[] = []

  async created() {
    await this.loadListPlansDeveloppement()
  }

  async loadListPlansDeveloppement() {
    await this.$http.myressif.entreprises
      .getListPlansDeveloppementByEntrepriseId(
        this.$route.params.id
      )
      .then((response: PlanDeveloppement[]) => {
        response
          .forEach((x) => {
            console.log('begin for', x.id)
            console.log(JSON.parse(x.contenu))
          })
        console.log(response)
        this.listPlansDeveloppement = response.map((x: PlanDeveloppement) => <PlanDeveloppement>{
            ...x,
            listLibelle: JSON.parse(x.libelle),
            listContenu: JSON.parse(x.contenu),
        })
      }
    )
  }

  async update(id: string, index: number, contenu: string) {
    var data = {contenu}
    await this.$http.myressif.entreprises
      .updatePlanDeveloppementEntreprise(this.$route.params.id, id, index, data)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du plan de développement",
            text: "Plan de développement modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListPlansDeveloppement();
      });
  }

  askDelete(id: string, index: number) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce plan de développement ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id, index);
        } else {
          return;
        }
      })
  }

  async confirmDelete(id: string, index: number) {
    await this.$http.myressif.entreprises
      .deletePLanDeveloppement(this.$route.params.id, id, index)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un plan de développement",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListPlansDeveloppement();
      })
  }
}
