



































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Site, UpdateSiteModel } from "@/api/models/sites/site";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BFormTextarea
  },
  name: "edit-site",
})
export default class EditSite extends Vue {
  @Prop({ required: true, type: Boolean })
  isUpdateSiteSidebarActive!: boolean;
  @Prop({ required: true }) siteId!: string;

  loading = false;
  loadingSite = false

  required = required;

  site: UpdateSiteModel = {};

  async created() {
      await this.$http.myressif.entreprises
          .getSiteById(this.$route.params.id, this.siteId)
          .then((res: UpdateSiteModel) => {
              this.site = res;
              this.loadingSite = true;
          });
  }

  resetForm() {
      this.site = {}
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.entreprises
      .updateSiteEntreprise(this.$route.params.id, this.site.id, this.site)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification d'un site",
            text: "Site modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-site");
        this.$emit("update:is-update-site-sidebar-active", false);
        this.loading = false;
        this.resetForm()
      });
  }
}
