















































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Contact, UpdateContactModel } from "@/api/models/contacts/contact";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import Categories from "@/api/models/enums/categoriesEnum";
import { droitDiffusion } from "@/api/models/droitsDiffusion/droitDiffusion";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-contact",
})
export default class EditContact extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateContactSidebarActive!: boolean;
  @Prop({ required: true }) contactId!: string;

  loading = false;
  required = required;
  loadingContact = false;

  categories = Categories;

  listAllDroitsDiffusion: droitDiffusion[] = []
  
  contact: UpdateContactModel = {
    listDroitsDiffusion: [],
  };

  async created() {
    await this.$http.myressif.entreprises
      .getContactById(this.$route.params.id, this.contactId)
      .then((res: Contact) => {
        this.contact = res;
        this.loadingContact = true;
      });
    await this.getDroitsDiffusion();
  }

  resetForm() {
    this.contact = {};
  }

  async submit() {
    this.loading = true;
    this.contact.listDroitsDiffusion = this.listAllDroitsDiffusion
    await this.$http.myressif.entreprises
      .updateContactEntreprise(
        this.$route.params.id,
        this.contactId,
        this.contact
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du contact",
            text: "Contact modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-contact");
        this.$emit("update:is-update-contact-sidebar-active", false);
        this.loading = false;
      });
  }

  async getDroitsDiffusion() {
    await this.$http.myressif.parametres
      .getByNom("droits_diffusion")
      .then((res: any) => {
        JSON.parse(res.valeur).forEach((x: any) => {
          var newDroitDiffusion: droitDiffusion = { name : x, value: this.contact.droitsDiffusion?.includes(x) ? true : false }
          this.listAllDroitsDiffusion?.push(newDroitDiffusion)
        });
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
    }
}
