




















































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { BilanActivite } from "@/api/models/bilansActivite/bilanActivite";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BBadge,
  BAlert
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/adherents/adherent";
import CreateBilanActivite from "./CreatebilanActivite.vue";
import UpdateBilanActivite from "./UpdatebilanActivite.vue";

@Component({
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BSpinner,
    BBadge,
    CreateBilanActivite,
    UpdateBilanActivite
  },
})
export default class ListBilansActivite extends Vue {
  @Prop({ required: true }) adherent!: Entreprise;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewBilanActiviteSidebarActive = false;
  isUpdateBilanActiviteSidebarActive = false;
  selectedBilanActiviteId = "";

  tableColumns = ['nomFichier', 'description', 'datePresentation', 'dateEnvoi','dateDerniereModification', 'actions'];
  paginatedListBilansActivite = new PaginatedList<BilanActivite>();

  async created() {
    await this.loadListBilansActivite({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListBilansActivite.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListBilansActivite.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListBilansActivite({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectBilanActivite(bilanActiviteId: string) {
    this.selectedBilanActiviteId = bilanActiviteId;
    this.isUpdateBilanActiviteSidebarActive = true;
  }

  async downloadFile(adherentId: string, bilanActiviteId: string) {
    await this.$http.myressif.entreprises
      .downloadBilanActivite(adherentId, bilanActiviteId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadListBilansActivite(params: any) {
    if (!params || params?.reset) {
      this.paginatedListBilansActivite = new PaginatedList<BilanActivite>();
    }
    await this.$http.myressif.entreprises
      .getListBilansActiviteByEntrepriseId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<BilanActivite>) => {
        this.paginatedListBilansActivite = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce bilan d'activite ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.entreprises
      .deleteBilanActivite(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un bilan d'activité",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListBilansActivite(null);
      });
  }
}
